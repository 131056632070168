import React from "react";
import { nanoid } from "nanoid";
import LOGO from "../../../Assets/images/logo/logoNavbar.png";
import {connect} from "react-redux";
import LongDateTH from "../../../utility/LongDateTH";
import DateTH from "../../../Helper/date-th";

const getUniqueParcels = (data) => {
  const parcelsSet = new Map();
  data.forEach((row) => {
      const key = `${row.shipment_status}-${row.awb_code}`;
      if(!parcelsSet.has(key)){
          parcelsSet.set(key,row);
      }
  });
  return [...parcelsSet.entries()];
};

function PdfPrint({ data,user }) {
    const date = new Date();
    let totalCod = 0;
    let totalNumber = 0;
    data = getUniqueParcels(data);
    return (
        <div style={{ margin: "0px" }}>
            <div style={{ textAlign: "center", fontSize: '25px' }}>
                <h2>ทะเบียนคุม (ขาออก)</h2>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px", fontSize: '20px' }}>
                <div style={{ justifyContent: "start", fontSize: '20px' }}>
                    <b>ชื่อพนักงาน : </b>{" "} {localStorage.getItem('fullname')}
                    <br />
                    <b>รหัสพนักงาน : </b>{" "} {localStorage.getItem('username')}
                    <br />
                    <b>วันเดือนปี : </b>{" "}
                    {(date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
                        "/" +
                        (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) +
                        "/" +
                        date.getFullYear()}{" "}
                    <br />
                </div>
                <div style={{ justifyContent: "end", fontSize: '20px' }}>
                    <img src={LOGO} alt="logo porlor" style={{ width: "150px", height: "70px" }} />
                    <div style={{ marginTop: "10px", fontSize: '20px'}}>บริษัท​ ปอ​ลอ เอ็กซ์เพ​รส​ จํากัด</div>
                </div>
            </div>
            <div style={{ marginRight: '5px'}}>
                <table>
                    <thead>
                    <tr style={{fontSize: '18px'}}>
                        <th style={{ textAlign: "center", fontSize: '18px', width: '5%'}}>No</th>
                        <th style={{ textAlign: "center", fontSize: '18px', width: '10%', overflowWrap:'break-word'}}>เลขที่ใบขนส่ง <br/> Order <br/>invoice</th>
                        <th style={{ textAlign: "center", fontSize: '18px', width: '5%' }}>จำน<br/>วนเอ<br/>กสา<br/>รนำ<br/>กลับ</th>
                        <th style={{ textAlign: "center", fontSize: '18px', width: '54%', overflowWrap:'break-word'}}>ชื่อผู้รับ</th>
                        <th style={{ textAlign: "center", fontSize: '18px', width: '5%'}}>ชิ้น</th>
                        <th style={{ textAlign: "center", fontSize: '18px', width: '5%' }}>COD (บาท)</th>
                        {/* <th style={{ textAlign: "center", fontSize: '18px', width: '14%'}}>หมาย<br/>เหตุ</th> */}
                    </tr>
                    </thead>
                    <tbody>
                    {data.map(([key,value], index) => {
                        totalCod+= parseFloat(value.cod_cost);
                        totalNumber+= parseFloat(value.num_parcel);
                        return (
                            <tr key={nanoid()}
                                style={{fontSize: '18px', pageBreakInside: 'avoid'}}> 
                                <td>{index + 1}</td>
                                <td style={{fontSize: '14px',textAlign: 'left',overflowWrap: 'break-word'}}>
                                    {value.ref_no !== "" ? "Con Note: ":""}
                                    {
                                        value?.ref_no !== "" && value?.ref_no?.length >= 8? 
                                        (
                                            <span style={{fontSize: '14px',textAlign: 'left',overflowWrap: 'break-word'}}>
                                            {value?.ref_no?.substring(0,8)} <br/> {value?.ref_no?.substring(8)}</span>
                                        ):(<span>{value.ref_no}</span>) 
                                    }
                                    <br/> บาร์โค้ด: {value.awb_code.substring(0,7)}<br/>
                                    {value.awb_code.substring(7)}
                                    <hr/>
                                    <DateTH date={value.created_date.split(" ")[0]} />
                                    <span style={{overflowWrap: 'anywhere'}}>{value.invoice || ""}</span>
                                </td>
                                <td style={{fontSize: '14px',textAlign: 'center'}}>{value.num_return_doc}</td>
                                <td style={{fontSize: '18px',textAlign: 'left',overflowWrap: 'break-word'}}>
                                    {value.receiver_name}
                                    <br/>
                                    ที่อยู่ : {`${value.receiver_address} ${value.receiver_amphur !== null ? value.receiver_amphur : ""} 
                                    ${value.receiver_district} ${value.receiver_province}`}
                                    <br/>
                                    โทรศัพท์ : {value.receiver_phone}
                                </td>
                                <td style={{fontSize: '14px',textAlign: 'right'}}>{value.num_parcel}</td>
                                <td style={{fontSize: '18px',textAlign: 'right'}}>{parseFloat(value.cod_cost).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                {/* <td style={{fontSize: '18px',textAlign: 'left',overflowWrap: 'break-word'}}>{value.remarkMG}</td> */}
                            </tr>
                        );
                    })}
                    <tr style={{fontSize: '18px'}}>
                        <td style={{textAlign:'right'}} colSpan={3}>ทั้งหมด</td>
                        <td style={{textAlign:'right'}}>{totalNumber}</td>
                        <td style={{textAlign:'right'}}>{totalCod}</td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>

            </div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "60px", fontSize: '18px' }}>
                <div style={{ marginRight: "50%", textAlign: "center", fontSize: '18px' }}>
                    ลงชื่อ​ผู้ส่ง​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​
                    <p>........../........../..........</p>
                </div>
                <div style={{ textAlign: "center", fontSize: '18px' }}>
                    ลงชื่อ​ผู้​ขน​ส่ง​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.
                    <p>........../........../..........</p>​
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.Authentication,
});

export default connect(mapStateToProps)(PdfPrint);
